const { createSlice } = require('@reduxjs/toolkit');

const discountSlice = createSlice({
    name: "discount",
    initialState: {
        discount: 0.0,
        code: "",
    },
    reducers: {
        setDiscount(state, action) {
            const discount = action.payload.discount;
            const code = action.payload.code;
            
            return state = {
                discount: discount,
                code: code,
            };
        },
    },
});

export const { setDiscount, setDiscountCode } = discountSlice.actions;
export default discountSlice.reducer;