const { createSlice } = require("@reduxjs/toolkit");

const grandTotalSlice = createSlice({
    name: "grandTotal",
    initialState: {
        total: 0.0
    },
    reducers: {
        setGrandTotal(state, action) {
            const total = action.payload.total;
            return state = {
                total: total,
            };
        },
    },
});

export const { setGrandTotal } = grandTotalSlice.actions;
export default grandTotalSlice.reducer;